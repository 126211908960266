import Backbone from 'backbone';
import $ from 'jquery';
import '../../freestartag';
import * as log from 'loglevel';

export const AdView = Backbone.View.extend({
    initialize: function (options) {
        /*
         * Ad View:
         * Instantiates the Ad with selected options
         */
        // slot configuration
        if (options.outOfPage) {
            this.slot = googletag.defineOutOfPageSlot(GPT.path, options.id);
        } else {
            this.slot = freestar.newAdSlots([
                {
                    placementName: options.placementName,
                    slotId: this.id,
                    targeting: { pos: options.pos },
                },
            ]);
        }

        // set this.el to element
        this.el = document.getElementById(options.id);
        this.$el = $(this.el);
        // set data-ad-setup attribute to determine which was have been
        // lazy loaded already
        this.$el.attr('data-ad-setup', 'true');
        // pub tags
        var pubads = googletag.pubads();
        if (this.$el.attr('data-ad-endEvent')) {
            pubads.addEventListener(
                'slotRenderEnded',
                function (event) {
                    if (event.slot === this.slot) {
                        log.info(
                            'Slot has been rendered:',
                            this.id,
                            'empty: ' + event.isEmpty
                        );
                    }
                    if (event.slot === this.slot && !event.isEmpty) {
                        // slot has been loaded, trigger global loaded event for this slot's id
                        log.info('ads.rendered-' + this.id);
                        $(document).trigger('ads.rendered-' + this.id);
                    }
                }.bind(this)
            );
        }
        // // this.slot.addService(pubads);
        // if (options.collapseEmpty) {
        //     // https://support.google.com/dfp_premium/answer/3072674?hl=en
        //     this.slot.setCollapseEmptyDiv(true, true);
        // }
        // this.refresh = options.refresh === false ? false : true;
        // if (GPT.SFP && options.platform === 'mobile') {
        //     this.slot.setTargeting('strnativekey', GPT.SFP);
        // }
        // this.slot.setTargeting('pos', options.pos);
        // if (window.xp1_auds && window.xp1_auds.Nodes) {
        //     this.slot.setTargeting('demographics', window.xp1_auds.Nodes.join(','));
        // } else if (window.GPT.demographics) {
        //     this.slot.setTargeting('demographics', window.GPT.demographics);
        // }
        // if (GPT.SITE) {
        //     this.slot.setTargeting('site', GPT.SITE);
        // }
        // if (GPT.category) {
        //     this.slot.setTargeting('category', GPT.category);
        // }
        // if (GPT.tag) {
        //     this.slot.setTargeting('tag', GPT.tag);
        // }
        // if (GPT.type) {
        //     this.slot.setTargeting('type', GPT.type);
        // }
        // if (GPT.specials) {
        //     this.slot.setTargeting('special', GPT.specials);
        // }
        // if (GPT.url) {
        //     this.slot.setTargeting('url', GPT.url);
        // }
        // if (GPT.pagetype) {
        //     this.slot.setTargeting('pagetype', GPT.pagetype);
        // }
        // if (GPT.segment) {
        //     this.slot.setTargeting('segment', GPT.segment);
        // }
        // if (GPT.camera) {
        //     this.slot.setTargeting('camera', GPT.camera);
        // }
        // if (GPT.post) {
        //     this.slot.setTargeting('post', GPT.post);
        // }
        // if (GPT.referrer) {
        //     this.slot.setTargeting('referrer', GPT.referrer);
        // }
        // // if (options.loading === 'lazy') {
        // //     this.slot.setTargeting('lazyload')
        // // }
        // this.slot.setTargeting('initialSessionPage', GPT.initialSessionPage);
        return this;
    },
});
