import Backbone from 'backbone';
import _ from 'lodash';
import $, { css } from 'jquery';
import '../../freestartag';
import * as log from 'loglevel';
import { AdView } from '@smithsonian/js/ads/freestar/views/AdView';

export const FreeStarAdsAppView = Backbone.View.extend({
    /*
     * Ads App View:
     * Manages which type of ads show be displayed (desktop or mobile)
     * and when they should be refreshed
     */

    video: false,
    activeAds: [],

    /*
     * when a number of user actions occur, the ads should refresh
     * this actionCount serves as placeholder for this.
     */
    actionCount: 0,

    settings: [
        {
            name: 'desktopMain', // id: 'div-gpt-ad-chuckweasley-1',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'desktopOneByOne',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'desktopSecondary', // id: 'div-gpt-ad-chuckweasley-secondary',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'desktopSideTop',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'desktopSideHouseTop', // id: 'div-gpt-ad-chuckweasley-house-top',
            placementName: 'smithsonianmag_rail_right_2',
        },
        {
            name: 'desktopSideHouseBottom', //id: 'div-gpt-ad-chuckweasley-house-bottom',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'desktopSideBottom',
            placementName: 'smithsonianmag_rail_right_2',
        },
        {
            name: 'desktopSideLongform',
            placementName: 'smithsonianmag_longform_rightrail_1',
        },
        {
            name: 'desktopInArticle', // ad overrides should be named as desktopInArticle-0 (starting with 0 for the first positioplacementName:"smithsonianmag_rail_right_1",
            placementName: 'smithsonianmag_article_incontent_dynamic',
            pos: ['inarticle'],
        },
        {
            name: 'desktopInArticle-0',
            placementName: 'smithsonianmag_article_incontent_top',
            pos: ['inarticle-0'],
        },
        {
            name: 'mobileMain', // id: 'div-gpt-ad-chuckweasley-mobile-1',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'mobileMainSecondary', // id: 'div-gpt-ad-chuckweasley-mobile-secondary',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'mobileBottom', // id: 'div-gpt-ad-chuckweasley-mobile-2',
            placementName: 'smithsonianmag_rail_right_1',
        },
        {
            name: 'mobileInArticle', // ad overrides should be named as mobileInArticle-0 (starting with 0 for the first position)
            placementName: 'smithsonianmag_rail_right_1',
            pos: ['mobile-inarticle'],
        },
        {
            name: 'mobileInArticle-0',
            placementName: 'smithsonianmag_rail_right_1',
            pos: ['mobile-inarticle-0'],
        },
        {
            name: 'smithsonianmag_1x1_bouncex', // 1x1 bouncex
            placementName: 'smithsonianmag_1x1_bouncex',
        },
    ],

    initialize: function (options) {
        _.bindAll(
            this,
            'adsViewportRefresh',
            'adSetup',
            'adSlotSetup',
            'getSettings',
            'onDOMContentLoaded'
        );

        options = options ? options : {};

        this.video = options.video ? true : false;

        // list of ads to disable. (ads are all enabled by default)
        this.disableAd = options.disableAd ? options.disableAd : null;

        _.each(
            options.ads,
            function (obj, key) {
                var settings = this.getSettings(obj.name);
                if (settings) {
                    _.extend(settings, obj.attrs);
                }
            },
            this
        );

        this.setup();

        if (options.mobileInArticleAds) {
            this.mobileInArticleAds = options.mobileInArticleAds;
        } else {
            if (window.GPT.mobile_order) {
                this.mobileInArticleAds = window.GPT.mobile_order
                    .replace(' ', '')
                    .split(',')
                    .map((pos) => parseInt(pos, 10));
            } else {
                this.mobileInArticleAds = [
                    2, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46,
                    49,
                ];
            }
        }
        if (options.desktopInArticleAds) {
            this.desktopInArticleAds = options.desktopInArticleAds;
        } else {
            if (window.GPT.desktop_order) {
                this.desktopInArticleAds = window.GPT.desktop_order
                    .replace(' ', '')
                    .split(',')
                    .map((pos) => parseInt(pos, 10));
            } else {
                this.desktopInArticleAds = [
                    2, 6, 11, 15, 19, 24, 29, 34, 39, 44, 49,
                ];
            }
        }

        // refresh ads on slideshow change
        $(document).on('slideshow:change', this.adsViewportRefresh);
        // refresh on essay change
        $(document).on('essay:change', this.adsViewportRefresh);

        // insert ad placeholders to the DOM
        document.addEventListener('DOMContentLoaded', this.onDOMContentLoaded, {
            once: true,
        });
        // bind photocontest ajax response, and re-setup AdsAppView with new ads
        document.addEventListener(
            'photocontest:pagination',
            function (evt) {
                // refresh ads
                this.paginate(evt.detail);
            }.bind(this)
        );
        document.addEventListener(
            'photocontest:open-lightbox',
            function (evt) {
                if (
                    document.getElementById(
                        'div-gpt-ad-chuckweasley-photocontest-top'
                    )
                ) {
                    var photoContestSlot = this.getSlots().find(function (s) {
                        return (
                            s.getSlotId().getDomId() ===
                            'div-gpt-ad-chuckweasley-photocontest-top'
                        );
                    });
                    if (photoContestSlot) {
                        // refresh existing ad slot
                        log.info('photocontest-ad.refresh');
                        googletag.pubads().refresh([photoContestSlot]);
                    } else {
                        // setup new ad slot
                        log.info('photocontest-ad.setup');
                        this.adSlotSetup(
                            null,
                            $('#div-gpt-ad-chuckweasley-photocontest-top')
                        );
                    }
                }
            }.bind(this)
        );
    },

    setup: function () {
        /*
         * Setup and instantiate ads
         * We need to reference 'this' (AdsAppView instance)
         * to get the ad settings, but we can't override 'this' within
         * the googletag.
         */
        var that = this;

        freestar.queue.push(function () {
            googletag.pubads().set('page_url', GPT.DOMAIN);
        });
        freestar.queue.push(function () {
            // video ads
            if (that.video) {
                googletag.companionAds().setRefreshUnfilledSlots(true);
            }
            // set targeting site
            googletag.pubads().setTargeting('Site', [window.GPT.SITE]);

            // // lazy loading settings
            // googletag.pubads().enableLazyLoad({
            //     // Fetch slots within 5 viewports.
            //     fetchMarginPercent: 200, // Render slots within 2 viewports.
            //     renderMarginPercent: 100, // Double the above values on mobile, where viewports are smaller
            //     // and users tend to scroll faster.
            //     mobileScaling: 2.0,
            // });

            // googletag
            //     .pubads()
            //     .addEventListener('impressionViewable', function (event) {
            //         // refresh ad 20s after an impression has occurred
            //         const $adSlot = $('#' + event.slot.getSlotElementId());
            //         if ($adSlot) {
            //             let addSettings = $adSlot.data('ad-settings');
            //             const settings = that.getSettings(addSettings);
            //             if (settings.refresh) {
            //                 setTimeout(function () {
            //                     console.log('ad refresh:', addSettings, event.slot.getSlotElementId());
            //                     googletag.pubads().refresh([event.slot]);
            //                 }, 20000);
            //             }
            //         }
            //     })
            //     .addEventListener('slotOnload', function (event) {
            //         var slot = event.slot;
            //         var slotId = slot.getSlotId().getDomId();
            //         var $adSlot;
            //         var $sidebarAdSlot;
            //         // initial position for ad slot
            //         if (slotId.match('desktopInArticle-[0-9]{0,}-sidebar')) {
            //             $adSlot = $('#' + slotId.replace('-sidebar', '')).parents('.ad-wrapper');
            //             $sidebarAdSlot = $('#' + slotId);
            //             $sidebarAdSlot
            //                 .parent()
            //                 .css('top', $adSlot.offset().top + 340);
            //         }
            //     });

            // googletag.enableServices();
        });
    },

    isMobile: function () {
        return window.innerWidth < 1000;
    },

    getSettings: function (settingsName) {
        return _.find(this.settings, function (item) {
            return settingsName === item.name;
        });
    },

    onDOMContentLoaded: function () {
        let $articleBody = $('[data-article-body]');
        let $articleBodyPg = $articleBody.children('p');
        let $articleSidebar = $('.article .articleRight');
        let $longformSidebar = $('.article .article-fullwidth-right-column');
        let adNamePrefix = 'desktopInArticle-';
        let baseAdSettings = 'desktopInArticle';
        // let articleAds = this.desktopInArticleAds;
        // let adNamePrefix = this.isMobile()
        //     ? 'mobileInArticle-'
        //     : 'desktopInArticle-';
        // let baseAdSettings = this.isMobile()
        //     ? 'mobileInArticle'
        //     : 'desktopInArticle';
        let articleAds = this.isMobile()
            ? this.mobileInArticleAds
            : this.desktopInArticleAds;
        let $mostPopular = $('#id_most_popular');
        const FULL_WIDTH_AD_HEIGHT = 380;
        const FULL_WIDTH_AD_MARGIN = 24;
        const FULL_WIDTH_AD_TOTAL_HEIGHT =
            FULL_WIDTH_AD_HEIGHT + 2 * FULL_WIDTH_AD_MARGIN;

        if (!_.isArray(articleAds)) {
            articleAds = _.range(articleAds, $articleBodyPg.length, articleAds);
        }

        // in-article ads
        // these ads are dynamically inserted into the DOM
        // mobile vs desktop breakpoint @ 1068px
        let bottoms = [];
        if ($articleBody.length) {
            _.each(
                articleAds,
                function (adPosition, key) {
                    let adSettings = baseAdSettings;
                    let $adNode;
                    let $sidebarAdNode;
                    let $sidebarLongformAdNode;
                    let adId = adNamePrefix + key;
                    log.info('in-article adSettings', adSettings, key);
                    if (this.getSettings(adId)) {
                        adSettings = adId;
                        log.info(
                            'in-article adSettings-X override',
                            adSettings
                        );
                    }
                    // loop should finish if there are there are no more paragraphs where to render ads
                    if (adPosition >= $articleBodyPg.length) {
                        return;
                    }
                    if (
                        (window.GPT &&
                            adPosition ===
                                window.GPT.desktop_video_player_ad_position &&
                            adSettings === 'desktopInArticle-0') ||
                        (adPosition === 2 &&
                            adSettings === 'desktopInArticle-0')
                    ) {
                        if (
                            window.GPT &&
                            window.GPT.disable_video_player === 'video_disabled'
                        ) {
                            // render just the ad, no full width
                            $adNode = $(
                                '<div id="' +
                                    adId +
                                    '" class="ad-slot ad-slot-video" data-ad-settings="' +
                                    adSettings +
                                    '"></div>'
                            );
                        } else {
                            if (window.GPT && this.isMobile()) {
                                $adNode = $(
                                    '<div id="FreeStarVideoAdContainer_mw"><div id="freestar-video-parent"><div id="freestar-video-child"><script>freestar.queue.push(function() {freestar.newVideo("FreeStarVideoAdContainer_mw");});</script></div></div></div></div>'
                                );
                            } else {
                                $adNode = $(
                                    '<div id="FreeStarVideoAdContainer"><div id="freestar-video-parent"><div id="freestar-video-child"><script>freestar.queue.push(function() {freestar.newVideo("FreeStarVideoAdContainer");});</script></div></div></div></div>'
                                );
                            }
                        }
                    } else if (
                        (window.GPT &&
                            adPosition ===
                                window.GPT.mobile_video_player_ad_position &&
                            this.isMobile()) ||
                        (adPosition === 2 && this.isMobile())
                    ) {
                        if (
                            window.GPT &&
                            window.GPT.disable_video_player === 'video_disabled'
                        ) {
                            // render just the ad, no full width
                            $adNode = $(
                                '<div id="' +
                                    adId +
                                    '" class="ad-slot ad-slot-video" data-ad-settings="' +
                                    adSettings +
                                    '"></div>'
                            );
                        } else {
                            $adNode = $(
                                '<div id="FreeStarVideoAdContainer_mw"><div id="freestar-video-parent"><div id="freestar-video-child"><script>freestar.queue.push(function() {freestar.newVideo("FreeStarVideoAdContainer_mw");});</script></div></div></div></div>'
                            );
                        }
                        // // render just the ad, no full width
                        // // $adNode = $(
                        // //     '<div id="' +
                        // //         adId +
                        // //         '" class="ad-slot" data-ad-settings="' +
                        // //         adSettings +
                        // //         '"></div>'
                        // // );
                        // $adNode = $(
                        //     '<div id="FreeStarVideoAdContainer"><div id="freestar-video-parent"><div id="freestar-video-child"></div></div></div></div>'
                        // );
                    } else if (this.isMobile()) {
                        // render just the ad, no full width
                        $adNode = $(
                            '<div id="' +
                                adId +
                                '" class="ad-slot article-slot-centered hidden-desktop" data-ad-settings="' +
                                adSettings +
                                '"></div>'
                        );
                    } else {
                        $adNode = $(
                            '<div class="ad-wrapper calc-full-width"><div class="ad-container"><div id="' +
                                adId +
                                '" class="ad-slot ad-article-slot" data-ad-settings="' +
                                adSettings +
                                '"></div></div></div>'
                        );
                        if (!this.isMobile()) {
                            // sidebar ad below full width ad
                            $sidebarAdNode = $(
                                '<div class="ad-sidebar-wrapper"><div id="' +
                                    adId +
                                    '-sidebar" class="ad-slot" data-ad-settings="desktopSideBottom" data-article-ad="' +
                                    adId +
                                    '"></div>'
                            );
                            $sidebarLongformAdNode = $(
                                '<div class="ad-sidebar-wrapper"><div id="' +
                                    adId +
                                    '-sidebar" class="ad-slot" data-ad-settings="desktopSideLongform" data-article-ad="' +
                                    adId +
                                    '"></div>'
                            );
                            $articleSidebar.append($sidebarAdNode);
                            $longformSidebar.append($sidebarLongformAdNode);
                        }
                    }

                    if (this.isMobile()) {
                        $adNode.addClass(
                            'article-slot-centered hidden-desktop'
                        );
                    }
                    if ($articleSidebar.length === 0) {
                        $('.article .article-columns').addClass(
                            'single-column'
                        );
                    }
                    if ($longformSidebar.length === 0) {
                        $('.article .article-fullwidth-right-column').addClass(
                            'single-column'
                        );
                    }
                    $($articleBodyPg[adPosition]).before($adNode);

                    function calcCurrentAdBottom() {
                        const bottom = $adNode.offset().top;
                        bottoms[key - 1] = bottom;
                        return bottom;
                    }

                    function calcCurrentAdHeight(topFunc, bottomFunc) {
                        const currentAdTop = topFunc();
                        const bottom = bottomFunc();
                        let adHeight;
                        if (key == 1) {
                            adHeight =
                                bottom - currentAdTop - FULL_WIDTH_AD_MARGIN;
                        } else {
                            adHeight = bottom - currentAdTop;
                        }
                        return adHeight;
                    }

                    if (
                        $articleSidebar.length &&
                        $sidebarAdNode !== undefined
                    ) {
                        function calcSidebarCurrentAdTop() {
                            let top;
                            if (key === 1) {
                                if ($mostPopular) {
                                    top =
                                        $mostPopular.offset().top +
                                        $mostPopular.height();
                                } else {
                                    top = $sidebarAdNode.offset().top;
                                }
                            } else {
                                top =
                                    bottoms[key - 2] +
                                    FULL_WIDTH_AD_TOTAL_HEIGHT;
                            }
                            return top;
                        }

                        if (key !== 1) {
                            $sidebarAdNode.css(
                                'top',
                                (key - 1) * FULL_WIDTH_AD_TOTAL_HEIGHT + 'px'
                            );
                        }
                        function setAdHeight() {
                            // Calculate height to align position for sidebar ad slot
                            const adHeight = calcCurrentAdHeight(
                                calcSidebarCurrentAdTop,
                                calcCurrentAdBottom
                            );
                            $sidebarAdNode.css('height', adHeight);
                        }
                        const intervalId = setInterval(setAdHeight, 500);
                        setTimeout(function () {
                            clearInterval(intervalId);
                        }, 50000);

                        // fire something at the end of a resize event
                        function resizedw() {
                            setAdHeight();
                        }

                        var doit;
                        window.onresize = function () {
                            clearTimeout(doit);
                            doit = setTimeout(resizedw, 250);
                        };
                    }

                    if (
                        $longformSidebar.length &&
                        $sidebarLongformAdNode !== undefined
                    ) {
                        // align position for sidebar ad slots
                        $sidebarLongformAdNode.css(
                            'top',
                            (key - 1) * FULL_WIDTH_AD_TOTAL_HEIGHT + 'px'
                        );

                        function calcSidebarLongformCurrentAdTop() {
                            let top;
                            if (key === 1) {
                                top = $longformSidebar.offset().top;
                            } else {
                                top =
                                    bottoms[key - 2] +
                                    FULL_WIDTH_AD_TOTAL_HEIGHT;
                            }
                            return top;
                        }
                        function setLongformAdHeight() {
                            // Calculate height to align position for sidebar ad slots
                            const adHeight = calcCurrentAdHeight(
                                calcSidebarLongformCurrentAdTop,
                                calcCurrentAdBottom
                            );
                            $sidebarLongformAdNode.css('height', adHeight);
                        }
                        const intervalId = setInterval(
                            setLongformAdHeight,
                            500
                        );
                        setTimeout(function () {
                            clearInterval(intervalId);
                        }, 50000);

                        // fire something at the end of a resize event
                        function resizedw() {
                            setLongformAdHeight();
                        }

                        var doit;
                        window.onresize = function () {
                            clearTimeout(doit);
                            doit = setTimeout(resizedw, 250);
                        };
                    }

                    // add padding at the bottom of the article when the ad is longer than the article
                    var $lastAd = $('.articleLeft .ad-wrapper');
                    if ($lastAd.length) {
                        var lastAdHeight =
                            $lastAd.offset().top + $lastAd.height() + 700;
                        var articleHeight =
                            $articleBody.offset().top + $articleBody.height();
                        if (lastAdHeight > articleHeight) {
                            $articleBody.css(
                                'padding-bottom',
                                lastAdHeight - articleHeight
                            );
                        }
                    }
                }.bind(this),
                this
            );
        }
        // add a trailling sidebar ad
        if (
            !this.isMobile() &&
            ($articleSidebar.children().length > 1 ||
                $longformSidebar.children().length > 1)
        ) {
            // sidebar ad below the last full width ad and above the `div.footerLogo`
            let adCnt;
            let $traillingAdNode;
            if ($articleSidebar.length) {
                adCnt = $articleSidebar.children().length;
                const adId = adNamePrefix + (adCnt + 1);
                $traillingAdNode = $(
                    '<div class="ad-sidebar-wrapper"><div id="' +
                        adId +
                        '-sidebar" class="ad-slot last" data-ad-settings="desktopSideBottom" data-article-ad="' +
                        adId +
                        '"></div>'
                );
                $articleSidebar.append($traillingAdNode);
            } else if ($longformSidebar.length) {
                adCnt = $longformSidebar.children().length;
                const adId = adNamePrefix + (adCnt + 1);
                $traillingAdNode = $(
                    '<div class="ad-sidebar-wrapper"><div id="' +
                        adId +
                        '-sidebar" class="ad-slot last" data-ad-settings="desktopSideLongform" data-article-ad="' +
                        adId +
                        '"></div>'
                );
                $longformSidebar.append($traillingAdNode);
            }

            if ($traillingAdNode !== undefined) {
                function setSidebarAdNodeTopHeight() {
                    let $footer = $('footer');
                    const fullWidthAdCnt = bottoms.length;
                    const previousBottom = bottoms[fullWidthAdCnt - 1];
                    const height =
                        $footer.offset().top -
                        previousBottom -
                        FULL_WIDTH_AD_TOTAL_HEIGHT;

                    if (previousBottom > 0) {
                        $traillingAdNode.css(
                            'top',
                            fullWidthAdCnt * FULL_WIDTH_AD_TOTAL_HEIGHT + 'px'
                        );
                        $traillingAdNode.css('height', height + 'px');
                    }
                }
                const intervalId = setInterval(setSidebarAdNodeTopHeight, 500);
                setTimeout(function () {
                    clearInterval(intervalId);
                }, 50000);

                // fire something at the end of a resize event
                function resizedw() {
                    setSidebarAdNodeTopHeight();
                }

                var doit;
                window.onresize = function () {
                    clearTimeout(doit);
                    doit = setTimeout(resizedw, 250);
                };
            }
        }
        this.adSetup();
    },

    adSetup: function () {
        log.info('adSetup');
        // check for ad spots that need to be activated based on the scroll location
        // run every time the user scrolls
        // filters ads based on rules and determines if it should be setup or not
        var that = this;
        var $ads = $('.ad-slot');

        var $filteredAds = $ads.filter(
            function (key, node) {
                // filter ads to check if there's any ad that will be setup
                // filtered ads will be a list of the ads that will be setup
                //
                // get the node
                var $node = $(node);
                // info for slot setup
                var slotId = $node.attr('id');
                var adSettings = $node.data('ad-settings');

                // AD CONFIGURATION VALIDATION
                // check if slot is configured to use ad settings
                if (
                    !adSettings ||
                    (that.disableAd &&
                        that.disableAd.indexOf(adSettings) !== -1)
                ) {
                    return false;
                }

                // AD SETTINGS
                var settings = _.extend(
                    {
                        id: slotId,
                    },
                    that.getSettings(adSettings)
                );

                // AD PLATFORM
                if (settings.platform === 'mobile' && !this.isMobile()) {
                    // if platform mobile, but window width is desktop
                    // do nothing
                    log.warn('Ad mobile only, but desktop window:', slotId);
                    return false;
                } else if (settings.platform === 'desktop' && this.isMobile()) {
                    // if platform desktop, but window width is mobile
                    // do nothing
                    log.warn('Ad desktop only, but mobile window:', slotId);
                    return false;
                }

                return true;
            }.bind(this)
        );

        log.info('Ads.scrollSetup', $filteredAds.length);
        freestar.queue.push(function () {
            $filteredAds.each(that.adSlotSetup);
        });
    },

    adSlotSetup: function (key, node) {
        // setups up a whole ad slot
        var $node = $(node);
        // info for slot setup
        var slotId = $node.attr('id');
        var adSettings = $node.data('ad-settings');

        // AD CONFIGURATION VALIDATION
        // check if slot is configured to use ad settings
        if (!adSettings) {
            return false;
        }

        // AD SETTINGS
        var settings = _.extend(
            {
                id: slotId,
            },
            this.getSettings(adSettings)
        );

        // AD INITIALIZATION
        // use id from the DOM node id
        // extend with settings defined in data-ad-settings
        // instantiate ad with these settings
        var ad = new AdView(settings);
        log.info('Ad successful setup: ', ad.id);
        // push ad to list of active ads
        this.activeAds.push(ad);

        // call googletag.display to display the ad slot
        // freestar.display(ad.id);

        // refresh the slot and show the ad
        //log.info('ad slot refresh', ad.id);
        //googletag.pubads().refresh([ad.slot]);

        // Trigger an event stating that the ad was finished setting up.
        // This is used on TweenTribune to determine if there is an
        // interstitial to show.
        $(document).trigger('ads.setup-' + ad.id, ad);
    },

    getVisibilityState: function () {
        // get visibility state for all implementations (including prefixed browsers)
        return (
            document.visibilityState ||
            document.webkitVisibilityState ||
            document.mozVisibilityState ||
            document.msVisibilityState
        );
    },

    getSlots: function (ads) {
        // return the list of slots (for refreshing purposes)
        // from a list of ads
        var slots = [];
        // if an ads list is passed, use that
        // else use all ads in this.activeAds
        _.each(
            ads ? ads : this.activeAds,
            function (obj, key) {
                // if slot can be refreshed
                if (obj.refresh) {
                    slots.push(obj.slot);
                }
            },
            this
        );
        return slots;
    },

    inViewport: function (elem) {
        log.info('inViewport', elem);
        // check if an element is in the viewport
        var $elem = $(elem);
        var offset = $elem.offset();
        var pos = {
            offsetTop: offset ? offset.top : 0,
            height: $elem.height(),
        };
        var scrollY = $(window).scrollTop();
        // check if a little over 50% of the ad is within the viewport
        var isInViewport =
            pos.offsetTop >= scrollY - pos.height / 2.25 &&
            pos.offsetTop < scrollY + window.innerHeight - pos.height / 2.25;
        return isInViewport;
    },

    adsViewportRefresh: function () {
        var self = this;
        // Refresh all ads between:
        // start: window.scrollY
        // end: window.scrollY + window.innerHeight
        var ads = _.filter(this.activeAds, function (obj, key) {
            return self.inViewport(obj.el) && obj.refresh;
        });

        log.info('adsViewportRefresh', ads);

        // get the slot list from the ad list
        var slots = this.getSlots(ads);
        // trigger ads refresh
        if (googletag.pubads && slots.length) {
            googletag.pubads().refresh(slots);
        }
    },
});
